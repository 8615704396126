import React, { useEffect, useRef } from 'react';
import Layout from '../components/layout';
import WebinarRegistrationPopup from '../components/Popup/webinar-registration-popup';
import SEO from "../components/seo";
import IPProtectionImg from '../images/aws-openai-soc.jpg';
import '../styles/webinar-page.css';

const WebinarAnAIPoweredPatent = () => {
  const WEBINAR_TITLE = 'An AI-Powered Patent Drafting Solution - A Must Try For Entrepreneurs';
  const seo = {
    metaDesc: WEBINAR_TITLE
  }
  const WEBINAR_AI_MEETS = "https://webinarkit.com/webinar/registration/oneclick/67c81b144c252cf9088775e1?date=jit_15";
  const childRef = useRef();
  const openPopup = () => {
    //
    childRef.current?.open(WEBINAR_AI_MEETS);
  }
  //
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <Layout activeMenu={'webinar'}>
      <SEO title={`Webinar ${WEBINAR_TITLE} - Inventiv.org`} canonical='/webinar-an-ai-powered-patent-drafting-solution-a-must-try-for-entrepreneurs' seo={seo} />

      <div class="sub-banner" style={{ height: 'auto', backgroundColor: '#0C6873' }}>
        <div class="container py-5">
          <div class="text-center">
            <h1 className='text-white'> AI & Machine Learning </h1>
            <h6 className='text-white'>The webinar is provided free of cost and is a collaboration between Inventiv and PatentPC</h6>
          </div>
        </div>
      </div>

      {/* <!-- Rsvp start --> */}
      <div class="rsvp content-area-2" id="rsvp">
        <div class="container px-5">
          <div className='px-5'>
            <div class="row">
              <div class="col-12 wow fadeInUp delay-04s">
                <div class="row">
                  <h1 className="text-center bold">{WEBINAR_TITLE}</h1>
                  <h5 className='text-center'>Created By Patent Lawyers, For Patent Lawyers. </h5>
                </div>
              </div>
            </div>
            <div className='my-5'>
              <div className='row'>
                <div className='col-md-12 row-flex wow fadeInUp delay-04s'>
                  <div className='div-content'>
                    <div className='circular_image'>
                      <img style={{ display: 'block', objectFit: 'cover' }} src='https://webinarkituploads.s3.us-east-2.amazonaws.com/user%2F6747df2bfba398dfcc5f14e8%2Fmohamed-nohassi-tdu54W07_gw-unsplash.jpg' alt={WEBINAR_TITLE}></img>
                    </div>
                    <p className='text-center'>Don’t miss this webinar by our partner PatentPC</p>
                    <h5 className='bold mt-2 text-center'>Instant watch session available. Join now!</h5>

                  </div>
                  <div className='div-content'>
                    <p>
                      Patents can be a game-changer for entrepreneurs. A well-drafted patent secures your innovation, keeping competitors at bay and increasing your business’s value. But drafting patents is tough, expensive, and time-consuming. That’s where Inventiv, an AI-powered patent drafting solution, changes the game. It helps entrepreneurs get high-quality patent drafts at a fraction of the usual time and cost.
                    </p>

                    <h3>Why Traditional Patent Drafting is a Challenge</h3>
                    <p>For startups and entrepreneurs, patent drafting is often a roadblock. Here’s why:</p>
                    <div className='ps-5'>
                      <ul style={{ listStyle: 'decimal' }}>
                        <li><strong>High Costs:</strong> Hiring a patent attorney can cost thousands of dollars per application.</li>
                        <li><strong>Time-Consuming:</strong> Drafting a patent takes weeks, sometimes even months.</li>
                        <li><strong>Complexity:</strong> The process is full of legal and technical jargon, making it hard for non-experts.</li>
                        <li><strong>Risk of Rejection:</strong> A poorly drafted patent increases the chances of rejection from the patent office, wasting both time and money.</li>
                      </ul>
                    </div>
                    <p className='text-center'>
                      <a href="#" onClick={e => { e.preventDefault(); openPopup() }} class="btn btn-default my-3" style={{ width: '100%' }}>Watch Webinar Now</a>
                    </p>

                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 wow fadeInUp delay-04s'>
                  <div className='div-content mw-350'>
                    <h3>Meet Inventiv: The Future of Patent Drafting</h3>
                    <p>Inventiv is an AI-powered tool designed to simplify and speed up the patent drafting process. Whether you’re an entrepreneur with a groundbreaking idea or a startup looking to protect its innovations, Inventiv ensures you get top-quality patent drafts without breaking the bank.
                    </p>
                    <p>Here’s what makes Inventiv a <strong>must-try</strong> for entrepreneurs:</p>
                    <h4 className='mt-3'>1. AI That Thinks Like a Patent Expert</h4>
                    <p>Inventiv isn’t just a keyword-based tool—it understands patent structures, legal requirements, and technical descriptions. It learns from thousands of high-quality patents to generate drafts that align with USPTO and global patent standards.</p>

                    <h4 className='mt-3'>2. Lightning-Fast Patent Drafts</h4>
                    <p>Time is money, and waiting weeks for a patent draft isn’t practical. Inventiv generates drafts in hours, not weeks. This speed lets you file patents faster, giving you an edge over competitors.</p>

                    <h4 className='mt-3'>3. Cost Savings That Matter</h4>
                    <p>Hiring a patent attorney can cost anywhere from $5,000 to $15,000 per patent application. Inventiv reduces this cost dramatically by automating much of the process, making patent protection affordable for startups and small businesses.</p>

                    <h4 className='mt-3'>4. User-Friendly Interface – No Legal Expertise Needed</h4>
                    <p>Many patent drafting tools require legal knowledge to use effectively. Not Inventiv. Its simple interface walks users through the process, helping even first-time entrepreneurs draft a patent without getting lost in legal jargon.</p>

                    <h4 className='mt-3'>5. Error Reduction and Compliance Assurance</h4>
                    <p>Mistakes in patent applications lead to rejections, costing time and money. Inventiv minimizes human error by ensuring compliance with patent office requirements. It flags potential issues before submission, reducing rejection risks.</p>

                    <h4 className='mt-3'>6. Customization for Every Industry</h4>
                    <p>Every industry has unique patent needs. Inventiv tailors drafts to fit various sectors, whether it’s <strong>software, biotechnology, mechanical inventions, or AI innovations</strong>. You get a patent draft that fits your industry perfectly.</p>

                    <h4 className='mt-3'>7. Collaboration with Human Experts</h4>
                    <p>While Inventiv is powerful, it doesn’t replace human expertise—it enhances it. Entrepreneurs can use AI-generated drafts as a strong foundation and collaborate with patent professionals for final refinement.</p>

                  </div>
                  <div className='div-content mw-350'>
                    <h3 className='mt-3'>How to Use Inventiv for Patent Drafting</h3>
                    <p>Inventiv is designed for ease of use, ensuring that entrepreneurs can draft patents efficiently. The process is intuitive, removing the need for extensive legal knowledge while maintaining high-quality output.</p>

                    <p>
                      <div className='ps-5'>
                        <ul style={{ listStyle: 'decimal' }}>
                          <li><strong>Enter Your Invention Details</strong> – Describe your innovation in simple terms.</li>
                          <li><strong>Let AI Analyze and Structure Your Patent</strong> – Inventiv structures a draft based on best practices.</li>
                          <li><strong>Review and Edit as Needed</strong> – Tweak sections, refine descriptions, and adjust claims.</li>
                          <li><strong>Generate Final Patent Draft</strong> – Get a complete, ready-to-file patent draft.</li>
                          <li><strong>Consult a Patent Professional (Optional)</strong> – Have a professional review it before submission.</li>
                        </ul>
                      </div>
                    </p>
                  </div>
                  <div className='div-content mw-350'>
                    <h3 className='mt-3'>Real-World Impact: How Entrepreneurs Benefit from Inventiv</h3>
                    <p><strong>Speeding Up Patent Filings for Startups:</strong></p>
                    <p>Startups working on cutting-edge technology often need to file multiple patents quickly. Inventiv allows them to create high-quality drafts at record speed, keeping them ahead of the competition.</p>

                    <p><strong>Reducing Costs for Bootstrapped Businesses</strong></p>
                    <p>Cash-strapped startups don’t have thousands to spend on every patent. By cutting down patent drafting costs, Inventiv ensures that even small businesses can protect their innovations.</p>

                    <p><strong>Helping First-Time Inventors Navigate Patent Complexity</strong></p>
                    <p>Many entrepreneurs have groundbreaking ideas but no knowledge of patent law. Inventiv simplifies the process, making patent protection accessible to everyone.</p>
                  </div>
                  <div className='div-content mw-350'>
                  <h3 className='mt-3'>The Future of AI in Patent Drafting</h3>
                  <p>AI is reshaping industries, and patent drafting is no exception. With Inventiv, entrepreneurs no longer need to spend weeks or thousands of dollars on drafting patents. As AI continues to evolve, solutions like Inventiv will only get smarter, faster, and more efficient.</p>
                  </div>

                  <div className='div-content mw-350'>
                    <h3 className='mt-3'>Conclusion: Why Every Entrepreneur Should Try Inventiv</h3>
                    <p>Patents are crucial for protecting intellectual property, but traditional patent drafting is expensive, slow, and complex. <strong>Inventiv eliminates these pain points</strong>, providing a fast, affordable, and high-quality patent drafting solution. Whether you’re an early-stage startup or a growing business, this AI-powered tool is a game-changer in securing your innovations.</p>

                    <p>
                    If you’re serious about protecting your ideas, <strong>Inventiv is a must-try</strong>. Give it a shot and experience the future of patent drafting today!
                    </p>
                  </div>



                </div>
              </div>
              <div className='row'>
                <div className='col-md-6 offset-md-3'>
                  <p className='text-center'>
                    <a href="#" onClick={e => { e.preventDefault(); openPopup() }} class="btn btn-default my-3" style={{ width: '100%' }}>Watch Webinar Now</a>
                  </p>
                </div>
              </div>
              {/* <div className='row'>
                <div class=" text-center col-md-6 wow fadeInUp delay-04s">
                  <div className='circular_image'>
                    <img style={{ display: 'block', objectFit: 'cover' }} src='https://webinarkituploads.s3.us-east-2.amazonaws.com/user%2F6747df2bfba398dfcc5f14e8%2Fmohamed-nohassi-tdu54W07_gw-unsplash.jpg' alt='An AI-Powered Patent Drafting Solution'></img>
                  </div>
                  <h5 className='bold mt-2'>Instant watch session available. Join now!</h5>
                </div>
                <div class="col-md-6 wow fadeInUp delay-04s">
                  <div className='my-element'>
                    <h3 className='bold text-center'>Here's what you'll learn:</h3>
                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'>Practical walkthrough: AI <strong>patent drafting</strong> in action</h5>
                    </div>

                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'>Avoiding common <strong>patent pitfalls</strong> with AI</h5>
                    </div>

                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'>Understanding the limits and <strong>ethics</strong> of AI in patents</h5>
                    </div>

                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'><strong>AI's revolutionary impact</strong> on patent drafting</h5>
                    </div>
                    <div>
                      <a href="#" onClick={e => { e.preventDefault(); openPopup() }} class="btn btn-default my-3" style={{ width: '100%' }}>Watch Webinar Now</a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className='container px-5'>
        <div class="d-flex justify-content-center">
          <img src={IPProtectionImg} alt='Our lawyers have worked on IP protection for' />
        </div>
      </div>
      <WebinarRegistrationPopup ref={childRef}></WebinarRegistrationPopup>
      {/* <!-- Rsvp end --> */}
    </Layout>
  )
};

export default WebinarAnAIPoweredPatent;